import * as React from "react";
import { useRoutes, Navigate, useParams } from "react-router-dom";

// Layouts
import { ProtectedLayout, UnprotectedLayout } from "layouts";

// Auth
import Login from "./auth/login";
import RegisterSchool from "./auth/register-school";
import RegisterUser from "./auth/register-user";

// Dashboard
import Dashboard from "./dashboard";
import Profile from "./dashboard/profile";
import EditProfile from "./dashboard/profile/edit";
import School from "./dashboard/school";
import SingleSchool from "./dashboard/school/single";
import EditSchool from "./dashboard/school/edit";
import Courses from "./dashboard/courses";
import CreateCourse from "./dashboard/courses/create";
import EditCourse from "./dashboard/courses/edit";
import SingleCourse from "./dashboard/courses/single";
import CourseTemplates from "./dashboard/courses/templates";
import SingleTemplate from "./dashboard/courses/templates/single";
import Team from "./dashboard/team";
import Students from "./dashboard/students";
import LoginForm from "./auth/login/components/forms/LoginForm";
import ForgotPasswordForm from "./auth/login/components/forms/ForgotPasswordForm";
import ResetPassword from "./auth/reset-password";
import Page404 from "./404";
import PermissionsGate from "roles/PermissionsGate";
import DynamicBreadcrumb from "components/breadcrumbs/DynamicBreadcrumb";
import EditMember from "./dashboard/team/edit";
import CreateMember from "./dashboard/team/create";
import Diplomas from "./dashboard/diplomas";
import SingleDiploma from "./dashboard/diplomas/single";
import CreateDiploma from "./dashboard/diplomas/components/CreateDiploma";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import Teaser from "./teaser/Teaser";

const DeleteToken = () => {
  localStorage.removeItem("token");
  return <Navigate to="/login" />;
};

const DiplomaTemplateElement = () => {
  const { diplomaId } = useParams();
  const { userData } = useContext(userContext);
  const { is_student } = userData || {};

  if (is_student) return <SingleDiploma />;

  return <Navigate to={`/diplomas/${diplomaId}/edit`} replace={true} />;
};

export const routes = [
  // Does not require login
  // {
  //   element: <UnprotectedLayout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: <Navigate to="/login" replace />,
  //       breadcrumb: "Login"
  //     },
  //     {
  //       path: "/login",
  //       element: (
  //         <Login>
  //           <LoginForm />
  //         </Login>
  //       )
  //     },
  //     {
  //       path: "/forgot-password",
  //       element: (
  //         <Login>
  //           <ForgotPasswordForm />
  //         </Login>
  //       )
  //     },
  //     {
  //       path: "/reset-password",
  //       element: <ResetPassword />
  //     },
  //     {
  //       path: "/school-register",
  //       element: <RegisterSchool />,
  //       breadcrumb: "School Register"
  //     },
  //     {
  //       path: "/user/activate",
  //       element: <RegisterUser />,
  //       breadcrumb: "Finish Registration"
  //     }
  //   ]
  // },
  // // Dashboard views (Required access rights)
  // {
  //   element: <ProtectedLayout />,
  //   children: [
  //     {
  //       element: <Dashboard />,
  //       children: [
  //         {
  //           path: "/profile",
  //           element: <Profile page="my-profile" />
  //         },
  //         {
  //           path: "/profile/edit",
  //           element: <EditProfile />,
  //           breadcrumb: "Edit My Details"
  //         },
  //         {
  //           path: "/school",
  //           element: <School />
  //         },
  //         {
  //           path: "/school/:schoolId",
  //           element: (
  //             <PermissionsGate permissions={["ROLE.STUDENT"]} redirect>
  //               <SingleSchool />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/school/edit",
  //           element: (
  //             <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
  //               <EditSchool />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Edit School Info"
  //         },
  //         {
  //           path: "/courses",
  //           element: <Courses />
  //         },
  //         {
  //           path: "/courses/create",
  //           element: (
  //             <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
  //               <CreateCourse />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Create Course"
  //         },
  //         {
  //           path: "/courses/:courseId",
  //           element: <SingleCourse />,
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/courses/:courseId/attend/:attendance_hash",
  //           element: (
  //             <PermissionsGate permissions={["ROLE.STUDENT"]} redirect>
  //               <SingleCourse />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: null
  //         },
  //         {
  //           path: "/courses/:courseId/edit",
  //           element: (
  //             <PermissionsGate permissions={["COURSE.UPDATE"]} redirect>
  //               <EditCourse />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Edit Course Info"
  //         },
  //         {
  //           path: "/courses/templates",
  //           element: (
  //             <PermissionsGate permissions={["COURSE.UPDATE"]} redirect>
  //               <CourseTemplates />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Course Templates"
  //         },
  //         {
  //           path: "/courses/templates/:courseId",
  //           element: (
  //             <PermissionsGate permissions={["COURSE.GET"]} redirect>
  //               <SingleTemplate />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/courses/:courseId/createFromTemplate",
  //           element: (
  //             <PermissionsGate permissions={["COURSE.CREATE"]} redirect>
  //               <EditCourse isCreateFromTemplate />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Create Course From Template"
  //         },
  //         {
  //           path: "/team",
  //           element: (
  //             <PermissionsGate permissions={["SCHOOL.GET"]} redirect>
  //               <Team />
  //             </PermissionsGate>
  //           )
  //         },
  //         {
  //           path: "/team/:memberId",
  //           element: (
  //             <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
  //               <Profile page="member" />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/team/:memberId/edit",
  //           element: (
  //             <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
  //               <EditMember />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Edit Member Details"
  //         },
  //         {
  //           element: (
  //             <PermissionsGate permissions={["SCHOOL.UPDATE"]} redirect>
  //               <CreateMember />
  //             </PermissionsGate>
  //           ),
  //           path: "/team/add-member",
  //           breadcrumb: "Add Member"
  //         },
  //         {
  //           path: "/students",
  //           element: (
  //             <PermissionsGate permissions={["STUDENT.GET"]} redirect>
  //               <Students />
  //             </PermissionsGate>
  //           )
  //         },
  //         {
  //           path: "/students/:studentId",
  //           element: (
  //             <PermissionsGate permissions={["STUDENT.GET"]} redirect>
  //               <Profile page="student" />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/students/:studentId/:diplomaId",
  //           element: (
  //             <PermissionsGate permissions={["STUDENT.GET"]} redirect>
  //               <SingleDiploma />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Diplomas"
  //         },
  //         {
  //           path: "/diplomas",
  //           element: <Diplomas />
  //         },
  //         {
  //           path: "/diplomas/:diplomaId",
  //           element: <DiplomaTemplateElement />,
  //           breadcrumb: DynamicBreadcrumb
  //         },
  //         {
  //           path: "/diplomas/:templateId/edit",
  //           element: (
  //             <PermissionsGate permissions={["STUDENT.GET"]} redirect>
  //               <CreateDiploma isEdit />
  //             </PermissionsGate>
  //           )
  //         },
  //         {
  //           path: "/diplomas/create",
  //           element: (
  //             <PermissionsGate permissions={["STUDENT.GET"]} redirect>
  //               <CreateDiploma />
  //             </PermissionsGate>
  //           ),
  //           breadcrumb: "Create Template"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   path: "/404",
  //   element: <Page404 />,
  //   breadcrumb: null
  // },
  // {
  //   path: "*",
  //   element: <Navigate to="/404" replace={true} />
  // },
  // {
  //   path: "/token-delete",
  //   element: <DeleteToken />
  // }
  {
    path: "/",
    element: <Teaser />
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />
  }
];

export function Router() {
  const routing = useRoutes(routes);

  return routing;
}
