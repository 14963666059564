import { ModalWrapper } from "./common/ModalWrapper";
import PopupTitleAndMessage from "./common/PopupTitleAndMessage";
import { ReactComponent as MetaMaskIcon } from "assets/icons/metamask.svg";
import classes from "./ConnectWalletPopup.module.scss";
import Button from "components/buttons/Button";

export interface IConnectWalletProps {
  connect: () => void;
}

const ConnectWallet = ({ connect }: IConnectWalletProps) => {
  return (
    <ModalWrapper size="md">
      <div>
        <PopupTitleAndMessage
          title="Connect Wallet"
          msg="Connect a wallet to get started."
        />
        <div className={classes["providers-container"]}>
          <Button
            className={classes["provider-btn"]}
            variant="neutral"
            onClick={connect}
          >
            <MetaMaskIcon />
            <span
              className={`${classes["u-text--primary"]} ${classes["u-text--small"]}`}
            >
              MetaMask
            </span>
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ConnectWallet;
