import { useContext, useEffect } from "react";
import classes from "./ModalWrapper.module.scss";
import { themeContext } from "context/themeContext";
import { popupContext } from "context/popupContext";
import { ReactComponent as closeIcon } from "assets/icons/close.svg";
import { colors } from "styles";

interface IProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  size?: "sm" | "md" | "lg";
  isClosable?: boolean;
}

const CloseIcon = closeIcon;

function ModalWrapper(props: IProps) {
  const { theme } = useContext(themeContext);
  const { clearPopup } = useContext(popupContext);
  const { onClose, size, isClosable } = props;

  // Remove popup on Esc key press
  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        event.preventDefault();
        closeHandler();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    // clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  });

  const closeHandler = () => {
    clearPopup();
    if (onClose) {
      onClose();
    }
  };

  return (
    <>
      <div className={`${classes["popup-wrapper"]}`} data-theme={theme}>
        <div
          className={classes["backdrop"]}
          onClick={isClosable ? closeHandler : null}
        />
        <div
          className={`${classes["content-wrapper"]} ${classes[size]} ${
            props.className ? classes[props.className] : ""
          }`}
        >
          {isClosable && (
            <CloseIcon
              className={classes["close-icon"]}
              fill={
                theme === "dark"
                  ? `rgb(${colors.neutral10})`
                  : `rgb(${colors.neutral100})`
              }
              onClick={closeHandler}
            />
          )}
          {props.children}
        </div>
      </div>
    </>
  );
}

ModalWrapper.defaultProps = {
  isClosable: true
};

export { ModalWrapper };
