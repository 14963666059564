import React, { useContext } from "react";
import classes from "./App.module.scss";
import { Toast } from "./components/toast/Toast";
import { toastContext } from "./context/toastContext";
import { Router } from "./routes/Routes";
import { popupContext } from "./context/popupContext";
import { createGlobalStyle } from "styled-components";
import { themeContext } from "context/themeContext";
import { colors } from "./styles";

// Handle global styles through `styled-components`
const GlobalStyle = createGlobalStyle`
html {
  background: ${(props) =>
    props.theme === "dark"
      ? `rgb(${colors.greyishBlue100})`
      : `rgb(${colors.white})`};
},


`;
function App() {
  const { toastData } = useContext(toastContext);
  const { popupData } = useContext(popupContext);
  const { theme } = useContext(themeContext);

  return (
    <div className={classes["AppWrapper"]} data-theme={theme}>
      {toastData ? <Toast msg={toastData.msg} type={toastData.type} /> : ""}
      {popupData}
      <Router />
      <GlobalStyle theme={theme} />
    </div>
  );
}

export default App;
