import { fetchApi } from "../utils/requests";

export const countriesDataQuery = () => {
  return {
    queryKey: ["countries-data"],
    queryFn: () =>
      fetchApi("account", "/location/countries", {
        method: "GET"
      })
  };
};

export const citiesDataQuery = (id: string) => {
  return {
    queryKey: ["cities-data", { stateId: id }],
    queryFn: () =>
      fetchApi("account", `/location/states/${id}/cities`, {
        method: "GET"
      })
  };
};

export const meDataQuery = () => {
  return {
    queryKey: ["me-data"],
    queryFn: () =>
      fetchApi("account", "/user/me", {
        method: "GET",
        auth: true
      })
  };
};

export const schoolDataQuery = () => {
  return {
    queryKey: ["school-data"],
    queryFn: () =>
      fetchApi("account", "/school", {
        method: "GET",
        auth: true
      })
  };
};

export const schoolsDataQuery = () => {
  return {
    queryKey: ["schools-data"],
    queryFn: () =>
      fetchApi("account", "/school/my-schools", {
        method: "GET",
        auth: true
      })
  };
};

export const singleSchoolDataQuery = (id: string) => {
  return {
    queryKey: ["schools-data", { id }],
    queryFn: () =>
      fetchApi("account", `/school/my-schools/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const schoolMemberDataQuery = (id: string) => {
  return {
    queryKey: ["team-data", { id }],
    queryFn: () =>
      fetchApi("account", `/school/members/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const permissionsDataQuery = () => {
  return {
    queryKey: ["permissions-data"],
    queryFn: () =>
      fetchApi("roles", "/role/permissions", {
        method: "GET"
      })
  };
};

// Build query keys from search params
export const buildQueryKey = (params) => {
  if (!params) return {};

  const splittedParams = params.split("&").slice(1);
  const arr = splittedParams.map((item) => {
    const [k, v] = item.split("=");
    if (v) {
      return {
        [k]: v
      };
    } else {
      return false;
    }
  });
  return arr.reduce(
    (a, v) => ({
      ...a,
      ...v
    }),
    {}
  );
};

export const teamDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams ? ["team-data", queryKeyParams] : ["team-data"],
    queryFn: () =>
      fetchApi(
        "account",
        hasQueryKeyParams
          ? `/school/members${searchParams}`
          : "/school/members",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const getAllRolesQuery = () => {
  return {
    queryKey: ["roles-data"],
    queryFn: () =>
      fetchApi("roles", "/role", {
        method: "GET",
        auth: true
      })
  };
};

export const coursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["courses-data", queryKeyParams]
      : ["courses-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/course${searchParams}` : "/course",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentCoursesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["student-courses-data", queryKeyParams]
      : ["student-courses-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/courses${searchParams}`
          : "/course-student/courses",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const singleCourseDataQuery = (id: string) => {
  return {
    queryKey: [`courses-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleStudentCourseDataQuery = (id: string) => {
  return {
    queryKey: [`student-courses-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course-student/courses/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleCourseStudentsDataQuery = (
  id: string,
  searchParams?: string
) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? [`course-students-data`, { id, ...queryKeyParams }]
      : [`course-students-data`, { id }],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course/${id}/students${searchParams}`
          : `/course/${id}/students`,
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const courseTemplatesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["course-templates-data", queryKeyParams]
      : ["course-templates-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course${searchParams}&is_template=true`
          : "/course?is_template=true",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const nftTemplatesDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["nft-templates-data", queryKeyParams]
      : ["nft-templates-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/template${searchParams}` : "/template",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const mintRequestsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["mint-requests", queryKeyParams]
      : ["mint-requests"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams ? `/mint-request${searchParams}` : "/mint-request",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentsDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["students-data", queryKeyParams]
      : ["students-data"],
    queryFn: () =>
      fetchApi(
        "account",
        hasQueryKeyParams
          ? `/school/students${searchParams}`
          : "/school/students",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentDataQuery = (id: string) => {
  return {
    queryKey: ["student-data", { id }],
    queryFn: () =>
      fetchApi("account", `/school/students/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const studentDiplomasDataQuery = (searchParams?: string) => {
  const queryKeyParams = buildQueryKey(searchParams);
  const hasQueryKeyParams = Object.keys(queryKeyParams).length > 0;
  return {
    queryKey: hasQueryKeyParams
      ? ["student-diplomas-data", queryKeyParams]
      : ["student-diplomas-data"],
    queryFn: () =>
      fetchApi(
        "courses",
        hasQueryKeyParams
          ? `/course-student/my-diplomas${searchParams}`
          : "/course-student/my-diplomas",
        {
          method: "GET",
          auth: true
        }
      )
  };
};

export const studentSingleDiplomaDataQuery = (id: string) => {
  return {
    queryKey: [`student-diplomas-data`, { id }],
    queryFn: () =>
      fetchApi("courses", `/course-student/my-diplomas/${id}`, {
        method: "GET",
        auth: true
      })
  };
};

export const singleDiplomaDataQuery = (userId: string, tokenId: string) => {
  return {
    queryKey: [`diplomas-data`, { userId, tokenId }],
    queryFn: () =>
      fetchApi("courses", `/course-student/diplomas/${userId}/${tokenId}`, {
        method: "GET",
        auth: true
      })
  };
};

export const connectWalletMutation = () => {
  return {
    mutationKey: ["school-wallet"],
    mutationFn: async (walletAddress: string) =>
      await fetchApi("account", "/user/wallet/connect", {
        method: "POST",
        auth: true,
        data: {
          wallet: walletAddress
        }
      })
  };
};

export const disconnectWalletMutation = () => {
  return {
    mutationKey: ["school-wallet"],
    mutationFn: async (walletAddress: string) =>
      await fetchApi("account", "/user/wallet/disconnect", {
        method: "POST",
        auth: true,
        data: {
          wallet: walletAddress
        }
      })
  };
};

export const singleDiplomaTemplateQuery = (id: string) => {
  return {
    queryKey: [`diploma-template`, { id }],
    queryFn: () =>
      fetchApi("courses", `/template/${id}`, {
        method: "GET",
        auth: true
      })
  };
};
