import React, { useContext, useState } from "react";
import { ethers } from "ethers";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { popupContext } from "context/popupContext";
import useComponentVisible from "hooks/useComponentVisible";
import ConnectWalletPopup from "components/popups/ConnectWalletPopup";
import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { userContext } from "context/userContext";
import { networkParams } from "utils/constants";
import classes from "./Connect.module.scss";
import { useMutationQuery } from "hooks/useMutationQuery";
import { connectWalletMutation, disconnectWalletMutation } from "query";
import { Web3Context } from "context/web3Context";
import Wallet from "./Wallet";
import WalletInfo from "./WalletInfo";

interface IConnect {
  isMobile: boolean;
}

const Connect = ({ isMobile }: IConnect) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const { setPopup, clearPopup } = useContext(popupContext);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(userContext);
  const { setWeb3User, clearWeb3User, web3UserData, usdPrice } =
    useContext(Web3Context);
  const { ethereum } = window;

  const { mutate: connectSchoolWallet } = useMutationQuery(
    connectWalletMutation()
  );

  const { mutate: disconnectSchoolWallet } = useMutationQuery(
    disconnectWalletMutation()
  );

  const disconnectWallet = () => {
    clearWeb3User();
    disconnectSchoolWallet();
  };

  const connectMetaMask = async () => {
    const steps = {
      provider: false,
      account: false,
      chain: false
    };

    setIsLoading(true);
    setPopup(
      <GenericPopup
        title="Waiting to Connect"
        msg="Confirm this connection in your wallet"
        hideActionBtn
        type="loading"
      />
    );

    let provider;
    let account;

    try {
      provider = new ethers.providers.Web3Provider(ethereum, "any");
      steps.provider = true;
    } catch (e) {
      setPopup(
        <GenericPopup
          title="Please install Metamask"
          msg="You need to install/enable Metamask in order to connect your wallet."
          type="error"
        />
      );
      setIsLoading(false);
      return;
    }

    if (provider && steps.provider) {
      if (provider.provider.isMetaMask) {
        try {
          account = await provider.send("eth_requestAccounts", []);
          steps.account = true;
        } catch (e) {
          clearPopup();
          setIsLoading(false);
        }
      } else {
        setPopup(
          <GenericPopup
            title={"Please install Metamask"}
            msg={
              "Metamask is currently the only supported wallet provider. Please install Metamask to interact with the DApp."
            }
            type="error"
          />
        );
      }

      if (
        provider?.provider?.chainId !== networkParams.mumbai.chainId &&
        steps.account
      ) {
        try {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: networkParams.mumbai.chainId }]
          });
          steps.chain = true;
        } catch (e) {
          clearWeb3User();
          setIsLoading(false);
          clearPopup();
          setPopup(
            <GenericPopup
              title="Wrong network!"
              msg="Please switch to the Polygon network."
              type="error"
            />
          );
          return;
        }
      }
    }

    if (provider && account) {
      const balanceInBN = await provider.send("eth_getBalance", [account[0]]);
      const balanceInEth = +ethers.utils.formatEther(balanceInBN);
      setWeb3User({
        wallet_address: account[0],
        balance: balanceInEth,
        balanceUsd: +(usdPrice * balanceInEth).toFixed(2)
      });
      connectSchoolWallet(account[0]);
      setPopup(
        <GenericPopup
          type="success"
          title="Connected!"
          msg="You have successfully connected your wallet."
        />
      );
    }
    setIsLoading(false);
  };

  if (userData && web3UserData?.wallet_address)
    return (
      <>
        <div
          ref={ref}
          className={classes["wallet-container"]}
          onClick={() => setIsComponentVisible(!isComponentVisible)}
        >
          <Wallet
            isVisible={isComponentVisible}
            setIsVisible={setIsComponentVisible}
          />
          <WalletInfo
            disconnectWallet={disconnectWallet}
            isVisible={isComponentVisible}
          />
        </div>
      </>
    );

  return (
    <Button
      onClick={() =>
        // TODO: check must be if (isMobile && !window.ethereum)
        // isMobile
        //   ? window.open(METAMASK_LOCAL_DEEPLINK, "_self")
        //   : setPopup(<ConnectWalletPopup connect={connectMetaMask} />)
        setPopup(<ConnectWalletPopup connect={connectMetaMask} />)
      }
      variant={isMobile ? "outline" : "neutral"}
      icon={WalletIcon}
      isFetching={isLoading}
    >
      Connect Wallet
    </Button>
  );
};

Connect.defaultProps = {
  isMobile: false
};

export default Connect;
