import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { ReactComponent as arrowNav } from "assets/icons/filter-arrow.svg";
import { routes } from "routes/Routes";
import classes from "./Breadcrumbs.module.scss";
import { useWindowSize } from "hooks/useWindowDimesions";
import { windowSizes } from "utils/windowSizes";
import TooltipComp from "components/tooltip/TooltipComp";

const ArrowIcon = arrowNav;

interface IBreadcrumbs {
  selectedSkips?: number[];
}

const Breadcrumbs = ({ selectedSkips }: IBreadcrumbs) => {
  const navigate = useNavigate();
  const breadcrumbs = useReactRouterBreadcrumbs(routes);
  const { width } = useWindowSize();

  let filteredCrumbs = breadcrumbs.filter(function (value, index) {
    return selectedSkips.indexOf(index) === -1;
  });

  const renderName = (i: number, name) => {
    if (width <= windowSizes.screenXlMax && i < filteredCrumbs.length - 1) {
      return (
        <TooltipComp id={`breadcrumb-${i}`} text={name}>
          ...
        </TooltipComp>
      );
    }
    return name;
  };

  return (
    <>
      {filteredCrumbs.length > 1 && (
        <span className={classes["nav"]}>
          {filteredCrumbs?.map((item, i) => {
            return (
              <Fragment key={i}>
                {
                  <div className={classes["nav-link"]}>
                    <div
                      className={
                        i < filteredCrumbs.length - 1
                          ? classes["link"]
                          : classes["u-text--primary"]
                      }
                      onClick={() =>
                        i < filteredCrumbs.length - 1 &&
                        navigate(item.match.pathname)
                      }
                    >
                      <div
                        className={
                          classes[width < windowSizes.screenXlMax ? "last" : ""]
                        }
                      >
                        {renderName(i, item.breadcrumb)}
                      </div>
                    </div>
                    {i < filteredCrumbs.length - 1 && (
                      <ArrowIcon className={classes["arrow"]} />
                    )}
                  </div>
                }
              </Fragment>
            );
          })}
        </span>
      )}
    </>
  );
};

Breadcrumbs.defaultProps = {
  selectedSkips: []
};

export default Breadcrumbs;
