import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(
  initialIsVisible: boolean | number,
  isDoubleClick?: boolean
) {
  const [isComponentVisible, setIsComponentVisible] =
    useState(initialIsVisible);
  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      isDoubleClick ? "dblclick" : "click",
      handleClickOutside,
      true
    );
    return () => {
      document.removeEventListener(
        isDoubleClick ? "dblclick" : "click",
        handleClickOutside,
        true
      );
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}
