export const ACCOUNT_MANAGEMENT_API_ENDPOINT = "https://user.opusnft.io";
export const COURSES_API_ENDPOINT = "https://courses.opusnft.io";
export const NOTIFICATIONS_API_ENDPOINT = "https://notification.opusnft.io";
export const ROLES_API_ENDPOINT = "https://roles.opusnft.io";

// Note: configure this link based on your own ip and port for testing on mobile device
// export const ACCOUNT_MANAGEMENT_API_ENDPOINT = "http://192.168.1.2:3001";
// export const COURSES_API_ENDPOINT = "http://192.168.1.2:3002";
// export const NOTIFICATIONS_API_ENDPOINT = "http://192.168.1.2:3003";
// export const ROLES_API_ENDPOINT = "http://192.168.1.2:3004";

export const FILES_PUBLIC_DOMAIN = "https://storage.googleapis.com/opus-dev/";
export const IPFS_GATEWAY = "https://ipfs.filebase.io/ipfs/";

// Note: configure this link based on your own ip and port
// Generate your link here: https://metamask.github.io/metamask-deeplinks/#
export const METAMASK_LOCAL_DEEPLINK =
  "https://metamask.app.link/dapp/192.168.1.2:3000";

export const polygontChainId = 137;

export const networkParams = {
  mumbai: {
    chainId: "0x13881",
    rpcUrls: ["https://rpc-mumbai.maticvigil.com/"],
    chainName: "Mumbai Testnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"]
  },
  polygon: {
    chainId: "0x89",
    rpcUrls: ["https://polygon-rpc.com/"],
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com"]
  }
};
