import { useMutation } from "@tanstack/react-query";

export const useMutationQuery = (func) => {
  return useMutation({
    ...func,
    onError: (err: Error) => {
      console.log(err);
    }
  });
};
