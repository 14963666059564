import {
  ACCOUNT_MANAGEMENT_API_ENDPOINT,
  COURSES_API_ENDPOINT,
  NOTIFICATIONS_API_ENDPOINT,
  ROLES_API_ENDPOINT
} from "./constants";
import { tokenData } from "./storage";

export type ReqMethods = "POST" | "GET" | "PUT" | "DELETE";

export interface fetchOpts {
  method: ReqMethods;
  auth?: boolean;
  data?: any;
  file?: Blob;
}

export async function fetchApi(
  endpoint: "account" | "courses" | "notifications" | "roles",
  path: string,
  opts: fetchOpts
) {
  const reqOpts: any = {};
  reqOpts.method = opts.method;
  if (opts.auth) {
    let token = localStorage.getItem("token");
    // Redirect if token is expired
    if (token && tokenExpired()) {
      window.location.assign(window.location.origin);
    }
    token = JSON.parse(token).token;
    reqOpts.headers = {
      Authorization: `Bearer ${token}`
    };
  }

  if (opts.data) {
    reqOpts.headers = {
      ...reqOpts.headers,
      "Content-Type": "application/json"
    };
    reqOpts.body = JSON.stringify(opts.data);
  }

  if (opts.file) {
    reqOpts.body = opts.file;
  }

  let endpointVar: string;
  switch (endpoint) {
    case "courses":
      endpointVar = COURSES_API_ENDPOINT;
      break;
    case "notifications":
      endpointVar = NOTIFICATIONS_API_ENDPOINT;
      break;
    case "roles":
      endpointVar = ROLES_API_ENDPOINT;
      break;
    default:
      endpointVar = ACCOUNT_MANAGEMENT_API_ENDPOINT;
      break;
  }

  const request = await fetch(endpointVar + path, reqOpts);
  const result = await request.json();

  if (result.statusCode >= 400) {
    throw new Error(result.message);
  }

  return result;
}

export function tokenExpired() {
  const now = Date.now();

  const expirationDate: tokenData = JSON.parse(localStorage.getItem("token"));
  const expDate = new Date(expirationDate?.exp);

  if (now > expDate.getTime()) {
    return true; // token expired
  }

  return false; // valid token
}

// Get `MATIC` to `USD` rate from `CoinGecko API`
export const getExchangeRate = async () => {
  const url =
    "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd";

  const request = await fetch(url);
  const result = await request.json();
  return result;
};
