import TooltipComp from "components/tooltip/TooltipComp";
import { useWindowSize } from "hooks/useWindowDimesions";
import { useNavigate } from "react-router-dom";
import PermissionsGate from "roles/PermissionsGate";
import { primaryItems } from "./Sidebar";
import classes from "./Tabs.module.scss";

interface ITabs {
  isCollapsed?: boolean;
  activeItem: number;
  setActiveItem: (item: number) => void;
  onClose?: () => void;
}

const Tabs = ({ isCollapsed, activeItem, setActiveItem, onClose }: ITabs) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();

  return (
    <>
      {primaryItems.map((item, i: number) => {
        return (
          <PermissionsGate
            key={i}
            permissions={
              i === 2 ? ["SCHOOL.GET"] : i === 3 ? ["STUDENT.GET"] : []
            }
          >
            <TooltipComp
              text={isCollapsed ? item.name : ""}
              id={item.name}
              position="right"
              width="full"
            >
              <div
                className={`${classes[`c-item`]} ${
                  item.id === activeItem ? classes["c-item--active"] : ""
                }`}
                onClick={() => {
                  setActiveItem(item.id);
                  navigate(item.link);
                  isMobile && onClose();
                }}
              >
                <div className={classes["icon"]}>
                  <item.icon />
                </div>
                {!isCollapsed && (
                  <div className={classes["title"]}>{item.name}</div>
                )}
              </div>
            </TooltipComp>
          </PermissionsGate>
        );
      })}
    </>
  );
};

export default Tabs;
