import classes from "./Loader.module.scss";

interface ILoaderProps {
  hasText?: boolean;
  size?: "xl" | "lg" | "md" | "sm";
  withPadding?: boolean;
  loaderColor?: "orange" | "white";
}

// Css loader component
// Ref.: https://tobiasahlin.com/spinkit/
const Loader = (props: ILoaderProps) => {
  const { size, hasText, withPadding, loaderColor } = props;

  return (
    <div
      className={`${classes["c-wrapper"]} ${
        withPadding ? classes["c-wrapper-padding"] : ""
      } `}
    >
      <div className={`${classes["c-loader"]} ${classes[`c-loader--${size}`]}`}>
        {[...Array(12)].map((e, i) => (
          <div
            key={i}
            className={`${classes[`c-loader-circle${i + 1}`]} ${
              classes["c-loader-circle"]
            } ${classes[loaderColor]}`}
          />
        ))}
      </div>
      {hasText && <span className={classes["c-loader-text"]}>Loading...</span>}
    </div>
  );
};

Loader.defaultProps = {
  hasText: false,
  size: "md",
  withPadding: false,
  loaderColor: "orange"
};

export default Loader;
