import Blockies from "react-blockies";
import classes from "./Blockies.module.scss";
import { colors } from "../../styles";

interface IBlockiesComp {
  seed: string;
  size: number;
  scale: number;
}

const BlockiesComp = ({ seed, size, scale }: IBlockiesComp) => {
  return (
    <Blockies
      seed={seed} // the only required prop; determines how the image is generated
      size={size} // number of squares wide/tall the image will be; default = 15
      scale={scale} // width/height of each square in pixels; default = 4
      color={`rgb(${colors.orange80})`} // normal color; random by default
      bgColor={`rgb(${colors.orange60})`} // background color; random by default
      spotColor={`rgb(${colors.orange40})`} // color of the more notable features; random by default
      className={classes["c-blockies"]} // optional class name for the canvas element; "identicon" by default
    />
  );
};

export default BlockiesComp;
