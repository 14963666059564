import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import { globalStyle } from "./styles";
import { ThemeProvider } from "./context/themeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastProvider } from "./context/toastContext";
import { UserProvider } from "./context/userContext";
import { BrowserRouter } from "react-router-dom";
import { PopupProvider } from "./context/popupContext";
import { PermissionsProvider } from "context/permissionsContext";
import { Web3Provider } from "context/web3Context";

// Create a client
const queryClient = new QueryClient();

// Handle global styles through `styled-components`
const GlobalStyle = createGlobalStyle`
  ${globalStyle}
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <PermissionsProvider>
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <PopupProvider>
            <UserProvider>
              <Web3Provider>
                <ThemeProvider>
                  <BrowserRouter>
                    <GlobalStyle />
                    <App />
                  </BrowserRouter>
                </ThemeProvider>
              </Web3Provider>
            </UserProvider>
          </PopupProvider>
        </ToastProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </PermissionsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
