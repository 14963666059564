export const windowSizes = {
  // Small tablets and large smartphones (landscape view)
  screenSmMax: 576,

  // Small tablets (portrait view)
  screenMdMax: 768,

  // Tablets and small desktops
  screenLgMax: 992,

  // Large tablets and desktops
  screenXlMax: 1230,

  // Extra large desktops
  screenXxlMax: 1440
};
