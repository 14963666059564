import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { userData } from "utils/storage";
import { tokenExpired } from "utils/requests";
import { meDataQuery, permissionsDataQuery, schoolDataQuery } from "query";
import { IFetchedPermissionsData } from "utils/apiDataTypes/RolesManagementDataTypes";
import { useGetFetchQuery } from "hooks/useGetFetchQuery";
import {
  IFetchedMeData,
  ISchoolInfo
} from "utils/apiDataTypes/AccountManagementDataTypes";
import { permissionsContext } from "./permissionsContext";
import { useQuery } from "@tanstack/react-query";
import Error from "components/error/Error";

interface IUserCtx {
  userData?: {
    email: string;
    is_student: boolean;
    role_flag: number;
    role_access: number;
    id: string;
  } | null;
  setUser?: any;
  clearUser?: any;
  tenant?: string;
}

export const userContext = createContext<IUserCtx>({});

export function UserProvider(props: any) {
  const [userData, setUserData] = useState<IUserCtx["userData"] | null>();
  const [tenant, setTenant] = useState("");
  const { setPermissions } = useContext(permissionsContext);

  const setUser = useCallback(
    (user: userData) => {
      setUserData(user);
    },
    [setUserData]
  );

  const clearUser = useCallback(() => {
    setUserData(null);
  }, [setUserData]);

  const fetchedMeData = useGetFetchQuery(meDataQuery()) as IFetchedMeData;
  const fetchedPermissionsData = useGetFetchQuery(
    permissionsDataQuery()
  ) as IFetchedPermissionsData;

  /* Set mandatory data on login */
  useEffect(() => {
    if (fetchedMeData && !userData) {
      setUserData({
        id: fetchedMeData?._id,
        email: fetchedMeData?.email,
        is_student: fetchedMeData?.role_flag === 1,
        role_flag: fetchedMeData?.role_flag,
        role_access: fetchedMeData?.role?.role_access
      });
    }
  }, [fetchedMeData]);

  /* Set Permissions */
  useEffect(() => {
    if (fetchedPermissionsData) {
      setPermissions(fetchedPermissionsData);
    }
  }, [fetchedPermissionsData]);

  // Fetch auth info in case cache data is missing
  // TODO - use `isLoading` and `error`
  const { isLoading: meDataIsLoading, error: meDataError } = useQuery<
    boolean,
    Error
  >({
    ...meDataQuery(),
    enabled: !fetchedMeData && !tokenExpired()
  });

  // Fetch auth info in case cache data is missing
  // TODO - use `isLoading` and `error`
  const { isLoading: permissionsDataLoading, error: permissionsDataError } =
    useQuery<boolean, Error>({
      ...permissionsDataQuery(),
      enabled: !fetchedPermissionsData && !tokenExpired()
    });

  // Fetch school data when a user is logged in
  const {
    isLoading: schoolDataIsLoading,
    error: schoolDataError,
    data: schoolData
  } = useQuery<boolean, Error, ISchoolInfo>({
    ...schoolDataQuery(),
    enabled: !!userData?.email && !userData?.is_student
  });
  useEffect(() => {
    if (schoolData) {
      setTenant(schoolData.tenant);
    }
  }, [schoolData]);

  if (permissionsDataError) {
    return <Error error={permissionsDataError} />;
  }

  return (
    <userContext.Provider value={{ userData, setUser, clearUser, tenant }}>
      {props.children}
    </userContext.Provider>
  );
}
