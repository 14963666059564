// ************************** I C O N S ******************************* //
import logoDark from "assets/images/logo-dark.svg";
import logoLight from "assets/images/logo-light.svg";
import logo from "assets/images/logo.svg";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import { ReactComponent as BookIcon } from "assets/icons/book.svg";
import { ReactComponent as TeamIcon } from "assets/icons/team.svg";
import { ReactComponent as StudentsIcon } from "assets/icons/students.svg";
import { ReactComponent as DiplomaIcon } from "assets/icons/diploma.svg";
import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
// ******************************************************************* //
import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { themeContext } from "context/themeContext";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Sidebar.module.scss";
import Button from "components/buttons/Button";
import { useWindowSize } from "hooks/useWindowDimesions";
import { windowSizes } from "utils/windowSizes";
import Tabs from "./Tabs";

interface IItems {
  id: number;
  name: string;
  icon: FC;
  link: string;
}

export const primaryItems: IItems[] = [
  {
    id: 0,
    name: "School",
    icon: SchoolIcon,
    link: "/school"
  },
  {
    id: 1,
    name: "Courses",
    icon: BookIcon,
    link: "/courses"
  },
  {
    id: 2,
    name: "Team",
    icon: TeamIcon,
    link: "/team"
  },
  {
    id: 3,
    name: "Students",
    icon: StudentsIcon,
    link: "/students"
  },
  {
    id: 4,
    name: "NFT Diplomas",
    icon: DiplomaIcon,
    link: "/diplomas"
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { theme, switchTheme } = useContext(themeContext);
  const [activeItem, setActiveItem] = useState<number>(0);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    localStorage.getItem("sidebar_collapsed") === "true"
  );
  const { width } = useWindowSize();

  const renderCollapseButton = useCallback(() => {
    if (width > windowSizes.screenXlMax) {
      return (
        <Button
          className={classes["c-btn--collapse"]}
          onClick={() => collapseHandler(!isCollapsed)}
          variant={"neutral"}
          size="small"
          isIconBtn
          icon={ChevronIcon}
          iconRotate={isCollapsed ? 270 : 90}
          isDisabled={width < windowSizes.screenXlMax}
        />
      );
    }
  }, [width, isCollapsed]);

  const collapseHandler = (collapse: boolean) => {
    localStorage.setItem("sidebar_collapsed", collapse.toString());
    setIsCollapsed(collapse);
  };

  useEffect(() => {
    if (!localStorage.getItem("sidebar_collapsed")) {
      localStorage.setItem("sidebar_collapsed", "false");
    }
  }, []);

  useEffect(() => {
    if (width <= windowSizes.screenXlMax) {
      collapseHandler(true);
    }
  }, [width]);

  useEffect(() => {
    const activeItem = primaryItems.find((item) =>
      location.pathname.includes(item.link) ? item.link : ""
    );
    if (activeItem) {
      return setActiveItem(activeItem.id);
    }
    return setActiveItem(null);
  }, [location.pathname]);

  return (
    <div
      className={`${classes["c-sidebar"]} ${
        isCollapsed ? classes["c-sidebar--collapsed"] : null
      }`}
    >
      {renderCollapseButton()}
      <div>
        <img
          className={classes["c-logo"]}
          src={isCollapsed ? logo : theme === "dark" ? logoLight : logoDark}
          height={56}
          onClick={() => navigate("/")}
          alt="Logo"
        />
        {/* Main Nav */}
        <div className={classes["c-items-wrapper"]}>
          <Tabs
            isCollapsed={isCollapsed}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        </div>
      </div>
      <div className={classes["c-items-wrapper"]}>
        {/* Theme Switcher */}
        <div className={classes["c-item"]} onClick={switchTheme}>
          <div className={classes["icon"]}>
            {theme === "dark" ? <SunIcon /> : <MoonIcon />}
          </div>
          {!isCollapsed && (
            <div className={classes["title"]}>{`${
              theme === "dark" ? "Light" : "Dark"
            } Theme`}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
