import Button from "components/buttons/Button";
import GenericPopup from "components/popups/GenericPopup";
import { ethers } from "ethers";
import { useMutationQuery } from "hooks/useMutationQuery";
import { connectWalletMutation, disconnectWalletMutation } from "query";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { networkParams } from "utils/constants";
import { getExchangeRate } from "utils/requests";
import { popupContext } from "./popupContext";
import { userContext } from "./userContext";

interface IWeb3Context {
  web3UserData: {
    wallet_address: string;
    balance: number;
    balanceUsd: number;
  };
  setWeb3User: (web3UserData: IWeb3Context["web3UserData"]) => void;
  clearWeb3User: () => void;
  usdPrice?: number;
}

export const Web3Context = createContext<IWeb3Context>(null);

export const Web3Provider = (props: any) => {
  const { ethereum } = window;
  const [web3UserData, setWeb3UserData] =
    useState<IWeb3Context["web3UserData"]>();
  const [usdPrice, setUsdPrice] = useState(0);
  const { userData } = useContext(userContext);
  const { setPopup, clearPopup } = useContext(popupContext);

  const setWeb3User = useCallback(
    (web3User: IWeb3Context["web3UserData"]) => {
      setWeb3UserData(web3User);
    },
    [setWeb3UserData]
  );

  const clearWeb3User = useCallback(() => {
    setWeb3UserData(null);
  }, [setWeb3UserData]);

  const provider = ethereum
    ? new ethers.providers.Web3Provider(ethereum, "any")
    : null;

  const { mutate: connectWallet } = useMutationQuery(connectWalletMutation());
  const { mutate: disconnectWallet } = useMutationQuery(
    disconnectWalletMutation()
  );

  /* Get the connected account */
  const getAccount = async () => {
    const [account] = await provider.listAccounts();
    return account;
  };

  /* Update user context with wallet info */
  const updateWalletInfo = async (account: string) => {
    const balanceInEth = await provider
      .send("eth_getBalance", [account])
      .then((res) => {
        return +ethers.utils.formatEther(res);
      });
    setWeb3UserData({
      wallet_address: account,
      balance: balanceInEth,
      balanceUsd: +(balanceInEth * usdPrice).toFixed(2)
    });
  };

  const renderWrongNetworkPopup = async () => {
    setPopup(
      <GenericPopup
        type="error"
        title="Check your network"
        msg="Please switch your network to continue"
        buttonName="Switch network in wallet"
        buttonVariant="contrast"
        clearPopupOnBtnAction={false}
        isClosable={false}
        buttonAction={async () => {
          try {
            await ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: networkParams.mumbai.chainId }]
            });
            const balanceInBN = await provider.send("eth_getBalance", [
              web3UserData.wallet_address
            ]);
            const balanceInEth = +ethers.utils.formatEther(balanceInBN);
            setWeb3UserData({
              wallet_address: web3UserData.wallet_address,
              balance: balanceInEth,
              balanceUsd: +(usdPrice * balanceInEth).toFixed(2)
            });
            clearPopup();
          } catch (e) {}
        }}
        bellowBtnComp={
          <Button
            variant="link"
            onClick={() => {
              clearWeb3User();
              clearPopup();
            }}
          >
            Disconnect Wallet
          </Button>
        }
      />
    );
  };

  /*
    After the mandatory data is set,then automatically update
    the userData with the wallet info, if a wallet is connected
  */
  useEffect(() => {
    if (provider) {
      getAccount().then((acc) => {
        if (acc && userData?.email) {
          updateWalletInfo(acc);
          connectWallet(acc as any);
        }
      });
    }
  }, [userData?.email, usdPrice]);

  /* Handle wallet accounts switch and disconnect */
  useEffect(() => {
    const handleSwitch = (accounts) => {
      let account = accounts[0];
      // Account connected
      if (account) {
        disconnectWallet();
        connectWallet(account);
        updateWalletInfo(account);
      }
      // Account disconnect
      else {
        disconnectWallet();
        clearWeb3User();
      }
    };

    ethereum && ethereum.on("accountsChanged", handleSwitch);
    return () => {
      ethereum && ethereum.removeListener("accountsChanged", handleSwitch);
    };
  }, [ethereum, userData]);

  /* Fetch and set the usd price */
  useEffect(() => {
    if (web3UserData?.wallet_address)
      getExchangeRate().then((res) => {
        setUsdPrice(res["matic-network"].usd);
      });
  }, [web3UserData?.wallet_address]);

  /* Detecting wrong chains */
  useEffect(() => {
    const switchChain = async (chainId: string) => {
      if (
        provider &&
        userData &&
        Number(chainId) !== Number(networkParams.mumbai.chainId) &&
        web3UserData.wallet_address
      ) {
        renderWrongNetworkPopup();
      }
    };

    /* Listener for switched chain. Display popup, if user is on the wrong chain */
    ethereum && ethereum.on("chainChanged", (chainId) => switchChain(chainId));

    return () => {
      ethereum && ethereum.removeListener("accountsChanged", switchChain);
    };
  }, [ethereum, userData]);

  return (
    <Web3Context.Provider
      value={{ web3UserData, setWeb3User, clearWeb3User, usdPrice }}
    >
      {props.children}
    </Web3Context.Provider>
  );
};
