import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { themeContext } from "context/themeContext";
import classes from "./GenericPopup.module.scss";
import { ModalWrapper } from "./common/ModalWrapper";
import { ReactComponent as SuccessIcon } from "assets/icons/success-big.svg";
import { ReactComponent as ErrorIcon } from "assets/icons/error-big.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info-big.svg";
import Button, { IButtonStyleProps } from "components/buttons/Button";
import Loader from "components/loader/Loader";
import { popupContext } from "context/popupContext";

interface IGenericProps {
  size?: "sm" | "md" | "lg";
  type?: "success" | "loading" | "error" | "info";
  title?: string;
  msg?: string;
  redirectPath?: string;
  icon?: React.ReactNode;
  buttonName?: string;
  buttonVariant?: IButtonStyleProps["variant"];
  buttonMinWidth?: "sm" | "md" | "full";
  buttonAction?: any;
  bellowBtnComp?: React.ReactNode;
  hideActionBtn?: boolean;
  clearPopupOnBtnAction?: boolean;
  isClosable?: boolean;
}

const GenericPopup = (props: IGenericProps) => {
  const navigate = useNavigate();
  const { clearPopup, popupIsLoading } = useContext(popupContext);
  const { theme } = useContext(themeContext);
  const {
    title,
    msg,
    redirectPath,
    icon,
    buttonName,
    buttonVariant,
    buttonAction,
    bellowBtnComp,
    size,
    type,
    hideActionBtn,
    clearPopupOnBtnAction,
    isClosable,
    buttonMinWidth
  } = props;

  const closeHandler = () => {
    clearPopupOnBtnAction && clearPopup();
    redirectPath && navigate(redirectPath);
    buttonAction && buttonAction();
  };

  const renderIcon = () => {
    // Use specific icon
    if (icon) return icon;

    // Use icon by type
    let modalIcon;
    switch (type) {
      case "loading":
        modalIcon = <Loader size="xl" />;
        break;
      case "error":
        modalIcon = <ErrorIcon />;
        break;
      case "info":
        modalIcon = <InfoIcon />;
        break;
      default:
        modalIcon = <SuccessIcon />;
    }
    return modalIcon;
  };

  const renderTitle = () => {
    // Use specific title
    if (title) return title;

    // Use title by type
    let modalTitle;
    switch (type) {
      case "loading":
        modalTitle = "Loading";
        break;
      case "error":
        modalTitle = "Error!";
        break;
      default:
        modalTitle = "Success!";
    }
    return modalTitle;
  };

  return (
    <ModalWrapper
      onClose={() => clearPopup()}
      size={size}
      isClosable={isClosable}
    >
      <div className={classes["popup-container"]} data-theme={theme}>
        <div className={classes[type === "loading" ? "loader" : "icon"]}>
          {renderIcon()}
        </div>
        <h4
          className={`${classes["title"]} ${classes["u-text--primary"]} ${classes["u-bold"]} ${classes["u-text--center"]}`}
        >
          {renderTitle()}
        </h4>
        {msg && <div className={classes["msg"]}>{msg}</div>}
        {!hideActionBtn && (
          <div className={classes["btn-container"]}>
            <Button
              variant={buttonVariant || "neutral"}
              onClick={closeHandler}
              minWidth={buttonMinWidth}
              size={"medium"}
              isFetching={popupIsLoading}
            >
              {buttonName || "Close"}
            </Button>
            {bellowBtnComp}
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};

GenericPopup.defaultProps = {
  size: "sm",
  type: "success",
  clearPopupOnBtnAction: true,
  isClosable: true,
  buttonMinWidth: "md"
};

export default GenericPopup;
