import BN from "bn.js";
import { useContext } from "react";
import { userContext } from "context/userContext";
import { permissionsContext } from "context/permissionsContext";
import { Navigate } from "react-router-dom";
import Loader from "components/loader/Loader";

interface IPermissions {
  permissions: (
    | "ROLE.STUDENT"
    | "ROLE.GET"
    | "ROLE.CREATE"
    | "ROLE.UPDATE"
    | "SCHOOL.GET"
    | "SCHOOL.CREATE"
    | "SCHOOL.UPDATE"
    | "USER.GET"
    | "USER.CREATE"
    | "USER.UPDATE"
    | "COURSE.GET"
    | "COURSE.CREATE"
    | "COURSE.UPDATE"
    | "STUDENT.GET"
    | "STUDENT.CREATE"
    | "STUDENT.UPDATE"
    | "STUDENT.DELETE"
    | "STUDENT.TEST"
  )[];
  redirect?: boolean;
  children: React.ReactNode;
}

export default function PermissionsGate({
  children,
  permissions,
  redirect
}: IPermissions) {
  const { userData } = useContext(userContext);
  const { permissionsData } = useContext(permissionsContext);

  const accessCode = userData ? new BN(userData.role_access) : new BN(0);

  const permissionsCodes = permissions.map((perm) => {
    return new BN(permissionsData[perm]);
  });

  const isAuthorized = permissionsCodes.map((permCode) => {
    return !accessCode.and(permCode).isZero();
  });

  if (
    accessCode.words[0] !== 0 && // accessCode has been set
    userData !== undefined && // userData has been set
    isAuthorized.some((value) => value === false) && // user not authorized
    redirect // redirect has been set
  ) {
    return <Navigate to="/school" />;
  }

  if (isAuthorized.some((value) => value === false))
    return redirect ? <Loader size="lg" hasText /> : null;

  return <>{children}</>;
}
