import { useQueryClient } from "@tanstack/react-query";

export const useGetFetchQuery = (query: {
  queryKey: (
    | string
    | { id: string }
    | { userId: string; tokenId: string }
    | { stateId: string }
  )[];
  queryFn: () => Promise<any>;
}) => {
  const queryClient = useQueryClient();
  return queryClient.getQueryData(query.queryKey);
};
