import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import classes from "./ThemeSwitcher.module.scss";
import { useContext } from "react";
import { themeContext } from "context/themeContext";

interface IThemeSwitcher {
  className?: string;
}

const ThemeSwitcher = (props: IThemeSwitcher) => {
  const { theme, switchTheme } = useContext(themeContext);

  return (
    <div className={props.className} data-theme={theme}>
      <div className={classes["c-switch"]} onClick={switchTheme}>
        <div className={classes["c-icon"]}>
          {theme === "dark" ? <SunIcon /> : <MoonIcon />}
        </div>
        <div className={classes["title"]}>{`${
          theme === "dark" ? "Light" : "Dark"
        } Theme`}</div>
      </div>
    </div>
  );
};

export default ThemeSwitcher;
