import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import logoLight from "assets/images/logo-light.svg";
import logoDark from "assets/images/logo-dark.svg";
import { themeContext } from "context/themeContext";
import { userContext } from "context/userContext";
import Breadcrumbs from "components/breadcrumbs/Breadcrumbs";
import User from "./user/User";
import Connect from "./connect/Connect";
import classes from "./Header.module.scss";
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburger.svg";
import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import Button from "components/buttons/Button";
import Divider from "components/divider/Divider";
import MobileMenu from "components/sidebar/mobile/MobileMenu";
import useComponentVisible from "hooks/useComponentVisible";
import ThemeSwitcher from "../theme-switcher/ThemeSwitcher";

export interface IHeaderProps {
  variant: "only-logo" | "only-buttons" | "both";
  withBreadcrumbs?: boolean;
  breadcrumbSkips?: number[];
}

const Header = (props: IHeaderProps) => {
  const { theme } = useContext(themeContext);
  const { userData } = useContext(userContext);
  const navigate = useNavigate();
  const { variant, withBreadcrumbs, breadcrumbSkips } = props;
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <>
      <div
        className={`${classes["header-wrapper"]} ${
          classes[!userData ? "no-auth" : ""]
        }`}
      >
        <div className={classes["logo-and-nav-container"]}>
          {(variant === "only-logo" || variant === "both") && (
            <img
              className={classes["logo"]}
              src={theme === "dark" ? logoLight : logoDark}
              height={56}
              onClick={() => navigate("/school")}
              alt="Logo"
            />
          )}
          {withBreadcrumbs && <Breadcrumbs selectedSkips={breadcrumbSkips} />}
        </div>
        {(variant === "only-buttons" || variant === "both") && (
          <div className={classes["header"]}>
            <User />
            {userData && !userData.is_student && (
              <>
                <div className={classes["divider"]} />
                <Connect />
              </>
            )}
          </div>
        )}
      </div>
      <div
        ref={ref}
        className={`${classes["header-mobile-wrapper"]} ${
          classes[!userData ? "no-auth" : ""]
        } ${classes[isComponentVisible ? "open-bg" : ""]}`}
      >
        <div className={classes["header-container"]}>
          <img
            className={classes["logo"]}
            src={theme === "dark" ? logoLight : logoDark}
            height={40}
            onClick={() => navigate("/school")}
            alt="Logo"
          />
          {!userData && <ThemeSwitcher className={classes["switcher"]} />}
          {userData && (
            <>
              <Button
                variant="link"
                isIconBtn
                icon={isComponentVisible ? CrossIcon : HamburgerIcon}
                onClick={() => setIsComponentVisible(!isComponentVisible)}
              />
              <div
                className={`${classes["mobile-menu-wrapper"]} ${
                  classes[isComponentVisible ? "open" : ""]
                }`}
              >
                <div className={classes["mobile-menu-container"]}>
                  <Connect isMobile />
                  <Divider />
                  <User
                    isMobile
                    closeMobileMenu={() => setIsComponentVisible(false)}
                  />
                  <Divider />
                  <div className={classes["tabs-container"]}>
                    <MobileMenu onClose={() => setIsComponentVisible(false)} />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <span className={classes["breadcrumbs-container"]}>
          {withBreadcrumbs && <Breadcrumbs selectedSkips={breadcrumbSkips} />}
        </span>
      </div>
    </>
  );
};

Header.defaultProps = {
  withBreadcrumbs: true
};

export default Header;
