import { createContext } from "react";
import useLocalStorage from "use-local-storage";

interface IThemeCtx {
  theme?: "dark" | "light" | string;
  switchTheme?: any;
}

export const themeContext = createContext<IThemeCtx>({});

export function ThemeProvider(props: any) {
  const [theme, setTheme] = useLocalStorage("theme", "light");

  const switchTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    setTheme(newTheme);
  };

  return (
    <themeContext.Provider value={{ theme, switchTheme }}>
      {props.children}
    </themeContext.Provider>
  );
}
