import Button from "components/buttons/Button";
import TooltipComp from "components/tooltip/TooltipComp";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import { ReactComponent as PowerOffIcon } from "assets/icons/power-off.svg";
import { ReactComponent as CheckCircleIcon } from "assets/icons/check-circle.svg";
import { useContext, useState } from "react";
import { Web3Context } from "context/web3Context";
import { toastContext } from "context/toastContext";
import { networkParams } from "utils/constants";
import classes from "./Connect.module.scss";
import { useWindowSize } from "hooks/useWindowDimesions";

interface IActionButtons {
  disconnectWallet: () => void;
}

const ActionButtons = ({ disconnectWallet }: IActionButtons) => {
  const [copied, setCopied] = useState<boolean>(false);
  const { web3UserData } = useContext(Web3Context);
  const { setToast } = useContext(toastContext);
  const { isMobile } = useWindowSize();

  return (
    <div
      className={classes[`action-buttons-wrapper${isMobile ? "-mobile" : ""}`]}
    >
      <TooltipComp id="copy" text="Copy">
        <Button
          onClick={() => {
            navigator.clipboard.writeText(web3UserData.wallet_address);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 3000);
            setToast({
              type: "info",
              msg: "Address copied successfully.",
              isSelfClosing: true,
              withTitle: false
            });
          }}
          icon={copied ? CheckCircleIcon : CopyIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
      <TooltipComp id="view" text="View on block explorer">
        <Button
          onClick={() =>
            window.open(
              `${networkParams.mumbai.blockExplorerUrls}/address/${web3UserData.wallet_address}`,
              "_blank"
            )
          }
          icon={LinkIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
      <TooltipComp id="disconnect" text="Disconnect">
        <Button
          onClick={disconnectWallet}
          icon={PowerOffIcon}
          variant={"link"}
          isIconBtn
        />
      </TooltipComp>
    </div>
  );
};

export default ActionButtons;
