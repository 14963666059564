import classes from "./Error.module.scss";
import React from "react";

interface IErrorProps {
  error: Error;
}

const Error = (props: IErrorProps) => {
  return (
    <div className={`${classes["u-text--primary"]} ${classes["u-text--small"]}`}>{props.error.message}</div>
  );
};

export default Error;
