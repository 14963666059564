import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as SchoolIcon } from "assets/icons/school.svg";
import classes from "./Avatar.module.scss";
import { FILES_PUBLIC_DOMAIN } from "utils/constants";

interface IAvatar {
  className?: string;
  src?: string;
  size?: "xl" | "lg" | "md" | "sm" | "xs";
  type?: "user" | "school";
}

const Avatar = (props: IAvatar) => {
  const { src, className, size, type } = props;

  if (!src)
    return (
      <div
        className={`${classes["c-avatar"]} ${classes["c-avatar--default"]} ${
          classes[`c-avatar--${size}`]
        } ${className}`}
      >
        {type === "school" ? <SchoolIcon /> : <UserIcon />}
      </div>
    );
  return (
    <img
      src={`${FILES_PUBLIC_DOMAIN}${src}`}
      className={`${classes["c-avatar"]} ${
        classes[`c-avatar--${size}`]
      } ${className}`}
      alt={`${type} avatar`}
    />
  );
};

Avatar.defaultProps = {
  size: "md",
  type: "user",
  alt: ""
};

export default Avatar;
