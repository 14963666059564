export const formatAddress = (addr: string, start?: number, end?: number) =>
  `${addr.slice(0, start || 5)}...${addr.slice(end || -4)}`;

export const shortenUrl = (url: string, symbols?: number) =>
  `${url.slice(0, symbols || 5)}...`;

export const formatNumber = (number: number) => {
  return number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const getFileNameFromUrl = (url: string) => {
  const splitedUrl = url.split("__");
  const splitedName = splitedUrl[1].split("?");
  return splitedName[0];
};

export const getFileName = (str: string) => {
  const splitedUrl = str.split(".");
  return splitedUrl[0];
};

export const removeSpaces = (str: string) => {
  const newString = str?.replace(/\s+/g, "-");
  return newString;
};

export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const rgbToHex = (rgb: string) => {
  const [r, g, b] = rgb.split(",");

  const red = parseInt(r);
  const green = parseInt(g);
  const blue = parseInt(b);

  // Use bit shifting and hexadecimal formatting to combine the values into a single hex value
  const hex = ((red << 16) | (green << 8) | blue).toString(16).padStart(6, "0");

  return "#" + hex;
};

export const formatSchoolAddress = (
  addr1,
  addr2,
  city,
  zip,
  state,
  country
) => {
  const schoolAddress: string[] = [
    addr1,
    addr2,
    city,
    `${state} ${zip || ""}`,
    country
  ];

  return schoolAddress
    .map((el) => el.trim())
    .filter(Boolean)
    .join(", ");
};
