import classes from "./Divider.module.scss";

interface IDivider {
  orientation?: "vertical" | "horizontal";
}

const Divider = ({ orientation }: IDivider) => {
  return (
    <div
      className={`${classes["c-divider"]} ${
        classes[
          orientation === "vertical"
            ? "c-divider--vertical"
            : "c-divider--horizontal"
        ]
      }`}
    />
  );
};

export default Divider;
