import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { userContext } from "context/userContext";
import useComponentVisible from "hooks/useComponentVisible";
import { ReactComponent as ChevronIcon } from "assets/icons/chevron.svg";
import Loader from "components/loader/Loader";
import Avatar from "components/avatar/Avatar";
import Button from "components/buttons/Button";
import ErrorComponent from "components/error/Error";
import { IFetchedMeData } from "utils/apiDataTypes/AccountManagementDataTypes";
import { clearTokenFromStorage } from "utils/storage";
import { meDataQuery } from "query";
import classes from "./User.module.scss";
import PermissionsGate from "roles/PermissionsGate";
import { ReactComponent as UserIcon } from "assets/icons/user.svg";
import { ReactComponent as PencilIcon } from "assets/icons/edit.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
import UserMobile from "./mobile/UserMobile";

export interface IItems {
  name: string;
  link: string;
  icon?: any;
}

export const menuItemsAdmin: IItems[] = [
  {
    name: "Edit School Details",
    link: "/school/edit",
    icon: PencilIcon
  }
];

export const menuItemsMobile: IItems[] = [
  {
    name: "My Profile",
    link: "/profile",
    icon: UserIcon
  }
];

export const menuItemsNotAdmin: IItems[] = [
  {
    name: "Edit Profile Details",
    link: "/profile/edit",
    icon: PencilIcon
  }
];

export const menuItemsCommon: IItems[] = [
  {
    name: "Log Out",
    link: "/",
    icon: LogoutIcon
  }
];

export interface IUser {
  isMobile: boolean;
  closeMobileMenu?: () => void;
}

const User = ({ isMobile, closeMobileMenu }: IUser) => {
  const navigate = useNavigate();
  const { clearUser } = useContext(userContext);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const queryClient = useQueryClient();

  // Fetch data
  const { isLoading, error, data } = useQuery<boolean, Error, IFetchedMeData>({
    ...meDataQuery()
  });

  if (isLoading)
    return (
      <div className={classes["user-container"]}>
        <Loader size="sm" />
      </div>
    );

  // Log out user
  const handleLogout = () => {
    clearUser();
    clearTokenFromStorage();
    queryClient.clear();
  };

  if (error && error.message === "Access Denied") handleLogout();

  const { pic, role_flag } = data;
  const isAdmin = role_flag === 4;
  const menuItems: IItems[] = [
    ...(isAdmin ? menuItemsAdmin : menuItemsNotAdmin),
    ...menuItemsCommon
  ];

  if (isMobile) menuItems.unshift(...menuItemsMobile);

  return (
    <>
      {!isMobile && (
        <div
          ref={ref}
          className={classes["user-container"]}
          onClick={() => navigate("/profile")}
          onMouseEnter={() => setIsComponentVisible(!isComponentVisible)}
          onMouseLeave={() => setIsComponentVisible(!isComponentVisible)}
        >
          <div className={classes["user-box"]}>
            <Avatar src={pic} />
            <div>
              {error ? (
                <ErrorComponent error={error} />
              ) : (
                <>
                  <div
                    className={`${classes["u-text--primary"]} ${classes["u-text--small"]} ${classes["u-bold"]}`}
                  >
                    {data.email}
                  </div>
                  <div
                    className={`${classes["u-text--content"]} ${classes["u-text--small"]}`}
                  >
                    {data.role.name}
                  </div>
                </>
              )}
            </div>
            <Button
              variant={"link"}
              size="medium"
              isIconBtn
              icon={ChevronIcon}
              iconRotate={isComponentVisible ? 180 : 0}
            />
          </div>
          {isComponentVisible && (
            <div className={classes["c-menu-wrapper"]}>
              <div className={classes["c-menu"]}>
                {menuItems.map(({ link, name }, i) => (
                  <PermissionsGate
                    permissions={
                      i === 0 && link.includes("school")
                        ? ["SCHOOL.UPDATE"]
                        : []
                    }
                    key={i}
                  >
                    <div
                      className={classes["c-menu-item"]}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (link === "/") handleLogout();
                        navigate(link);
                        setIsComponentVisible(false);
                        closeMobileMenu && closeMobileMenu();
                      }}
                    >
                      {name}
                    </div>
                  </PermissionsGate>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
      {isMobile && (
        <UserMobile
          meData={data}
          menuItems={menuItems}
          isAdmin={isAdmin}
          handleLogout={handleLogout}
          closeMobileMenu={() => closeMobileMenu()}
        />
      )}
    </>
  );
};

User.defaultProps = {
  isMobile: false,
  closeMobileMenu: null
};

export default User;
