import { ReactComponent as Logo } from "assets/images/logo-3D.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "assets/icons/linkedin.svg";
import classes from "./Teaser.module.scss";
import Header from "components/header/Header";
import ThemeSwitcher from "components/theme-switcher/ThemeSwitcher";
import { useWindowSize } from "hooks/useWindowDimesions";

const Teaser = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className={`${classes["wrapper"]}`}>
      <Header variant="only-logo" />
      {!isMobile && <ThemeSwitcher className={classes["theme-switcher"]} />}
      <div className={`${classes["wrapper-container"]} `}>
        <Logo width={200} height={200} />
        <div className={classes["text-container"]}>
          <h1>Coming Soon</h1>
          <p className={classes["u-text--content"]}>
            OpusNFT page currently in development. Stay tuned for more.
          </p>
        </div>
        {/* <div className={classes["socials-container"]}>
          <div className={classes["u-text--content"]}>Follow Us:</div>
          <div className={classes["icons-container"]}>
            <a href="https://twitter.com/" target="_blank" rel="noreferrer">
              <TwitterIcon />
            </a>
            <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
              <LinkedinIcon />
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Teaser;
