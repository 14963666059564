import React, { createContext, useCallback, useState } from "react";
import { IFetchedPermissionsData } from "utils/apiDataTypes/RolesManagementDataTypes";

interface IPermissionsData {
  permissionsData?: Object;
  setPermissions?: any;
  clearPermissions?: any;
}

export const permissionsContext = createContext<IPermissionsData>({});

export const PermissionsProvider = (props: any) => {
  const [permissionsData, setPermissionsData] = useState({});

  const setPermissions = useCallback(
    (permissions: IFetchedPermissionsData) => {
      setPermissionsData(permissions);
    },
    [setPermissionsData]
  );

  const clearPermissions = useCallback(() => {
    setPermissionsData(null);
  }, [setPermissionsData]);

  return (
    <permissionsContext.Provider
      value={{ permissionsData, setPermissions, clearPermissions }}
    >
      {props.children}
    </permissionsContext.Provider>
  );
};
