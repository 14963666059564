export interface userData {
  email: string;
  isAdmin: boolean;
  is_student: boolean;
  role_flag: number;
  role_access: number;
  id: string;
  wallet_balance: string;
  balance: number;
}

export function setUserInStorage(data: userData) {
  localStorage.setItem("user", JSON.stringify(data));
}

export function clearUserFromStorage() {
  localStorage.removeItem("user");
}
export interface tokenData {
  token: string;
  exp?: number;
  stayLoggedIn: boolean;
}
export function setTokenInStorage(data: tokenData) {
  let expiration = new Date();
  data = {
    ...data,
    exp: data.stayLoggedIn
      ? // expires after 6 months (due to missing check on the BE side for now)
        expiration.setMonth(expiration.getMonth() + 6)
      : // expires after 2 hours
        expiration.setTime(expiration.getTime() + 2 * 60 * 60 * 1000)
  };
  localStorage.setItem("token", JSON.stringify(data));
}

export function clearTokenFromStorage() {
  localStorage.removeItem("token");
}

// Ref. https://www.sohamkamani.com/javascript/localstorage-with-ttl-expiry
// Currently used for `attendance_hash` logic.
// Please modify to use in any other cases if needed.
export function setWithExpiry(key, value, ttl, courseId) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value,
    expiry: now.getTime() + ttl,
    courseId
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item;
}
