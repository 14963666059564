import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { primaryItems } from "../Sidebar";
import { ReactComponent as MoonIcon } from "assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "assets/icons/sun.svg";
import classes from "./MobileMenu.module.scss";
import { themeContext } from "context/themeContext";
import Divider from "components/divider/Divider";
import Tabs from "../Tabs";

interface IMobileMenu {
  onClose: () => void;
}

const MobileMenu = ({ onClose }: IMobileMenu) => {
  const location = useLocation();
  const [activeItem, setActiveItem] = useState<number>(0);
  const { switchTheme, theme } = useContext(themeContext);

  useEffect(() => {
    const activeItem = primaryItems.find((item) =>
      location.pathname.includes(item.link) ? item.link : ""
    );
    if (activeItem) {
      return setActiveItem(activeItem.id);
    }
    return setActiveItem(null);
  }, [location.pathname]);

  return (
    <div className={classes["c-items-wrapper"]}>
      <Tabs
        activeItem={activeItem}
        setActiveItem={setActiveItem}
        onClose={onClose}
      />
      <Divider />
      {/* Theme Switcher */}
      <div className={classes["c-items-wrapper"]}>
        <div className={classes["c-item"]} onClick={switchTheme}>
          <div className={classes["icon"]}>
            {theme === "dark" ? <SunIcon /> : <MoonIcon />}
          </div>
          <div className={classes["title"]}>{`${
            theme === "dark" ? "Light" : "Dark"
          } Theme`}</div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
